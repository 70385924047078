'use strict';

var appDom = require('../../app-dom');
var collage = require('./collage');

var init = function() {

	if (appDom.document.find('.gallery-image-border').length) {

		collage.init();

	}

};

module.exports = {
	init: init
};
