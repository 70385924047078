'use strict';

var setImageOrientation = function() {
	var collageImages = Array.from(document.getElementsByClassName('gallery-image-border'));

	collageImages.forEach(function(item, index) {
		var image = item.getElementsByTagName("img")[0];

		if (!image) {
			return;
		}
		image.onload = function() {

			console.log('IMAGE: ', image);
			console.log('HEIGHT: ', image.naturalHeight);
			console.log('WIDTH: ', image.naturalWidth);

			if (image.height > image.width) {
				item.className = 'gallery-image-border portrait';
			}
		}

	});
}

var init = function() {

	setImageOrientation();

};

module.exports = {
	init: init
};
